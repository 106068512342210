<template>
  <b-table
    :data="data.data"
    :striped="true"
    :narrowed="true"
    :sortable="sortable"
    default-sort-direction="asc"
    :sticky-header="data.data.length>perPage"
    :debounce-search="300"
    :paginated="isPaginated"
    :per-page="perPage"
    :current-page="currentPage"
    :height="height"

  >
    <b-table-column
      v-for="(field,index) in data.fields"
      :key="index"
      :field="field.field"
      :label="field.labelShort?field.labelShort:field.label"
      :numeric="field.type==='numeric'"
      :sortable="sortable"
      :searchable="field.type!=='numeric'&&searchable"

    >
      <template v-slot:header="{ column }">
        <b-tooltip
          v-if="column.label!==field.label"
          :label="field.label" append-to-body dashed
        >{{ column.label }}</b-tooltip>
        <span v-else>{{ column.label }}</span>
      </template>
      <template v-slot="props">
        <b-tooltip v-if="field.field==='variable' && props.row[field.field].length > printLen">
          <template v-slot:content>{{ props.row[field.field] }}</template>{{ props.row[field.field] | subStr }}
        </b-tooltip>
        <span v-else>
          {{ props.row[field.field] }}
        </span>
      </template>

    </b-table-column>
  </b-table>
</template>

<script>
import { table } from '@/mixins/table'
export default {
  name: 'SummaryTable',
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  mixins: [table]
}
</script>

<style>
.table thead th {
  border-bottom: 2px solid;
}
</style>
